<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="门店名称">
          <store-list-select v-model="query.shopId"></store-list-select>
        </query-item>
        <query-item label="角色名称">
          <a-input v-model="query.roleName" placeholder="角色名称"></a-input>
        </query-item>
        <query-item label="角色状态">
          <a-select allowClear v-model="query.status">
            <a-select-option :value="0">
              正常
            </a-select-option>
            <a-select-option :value="1">
              禁用
            </a-select-option>
          </a-select>
        </query-item>
        <query-item label="创建时间">
          <i-date-picker v-model="query.duration"></i-date-picker>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { roleList, roleStatus } from '@/api/user';
import queryMixin from '@/mixin/queryMixin';
import IDatePicker from '../../components/IDatePicker.vue';

export default {
  name: 'RoleList',
  mixins: [queryMixin],
  components: {
    IDatePicker
  },
  props: {},
  data() {
    return {
      query: {
        shopId: null,
        roleName: null,
        status: null,
        duration: null,
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          width: 200,
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key: 'roleName',
          width: 200,
        },
        {
          title: '角色说明',
          dataIndex: 'remark',
          key: 'remark',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          customRender: (text, row, index) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row, index) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
              </section>
            );
          }
        }
      ],
      dataList: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.handleQuery();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      if (this.params.duration && this.params.duration.length) {
        this.params.startDate = this.params.duration[0];
        this.params.endDate = this.params.duration[1];
        delete this.params.duration;
      }
      this.dataList = [];
      roleList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        this.dataList = list;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'roleAdd'
      });
    },

    // 查看
    handleInfo(row) {
      this.$router.push({
        name: 'roleInfo',
        params: {
          id: row.roleId
        }
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'roleEdit',
        params: {
          id: row.roleId
        }
      });
    },

    handleStatus(row) {
      const params = {
        roleId: row.roleId,
        status: row.status === 0 ? 1 : 0
      };

      roleStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },

    // 删除
    handleDelete(row, index) {
      this.dataList.splice(index, 1);
    }
  }
};
</script>

<style lang="less" scoped>

</style>
